
  <div class="app" :style="setStyle()">
    <div id="nav">
      <img class="logo" src="./assets/img/logo@2x.png" alt="">
      <div class="title" id="title">
        <router-link :class="['navItem', index == num ? 'navItem1' : '']" v-for="(item, index) in nav" :key="index"
          :to="item.router" @click="changeType(index)">{{ item.type }}</router-link>
      </div>
    </div>

    <router-view />


    <img class="topimg" v-show="topimgShow" src="./assets/img/top.png" @click="jumpClick()" alt="">
    <div :class="[num == 4 ? 'footer2' : 'footer']">
      <div class="record">
        <img class="ba" src="./assets/img/ba@2x.png" alt="">
        <span class="desc" id="move1" @click="todesc()" @mousemove="desc()"
          @mouseout="desc2()">粤公网备案44030402004913号</span>
      </div>
      <div class="btnTitle">© 2023 - 妙语教育 版权所有</div>
      <div class="btnBox">

        <div class="btnTitle2" id="move2" @click="tobtnTitle()" @mousemove="btnTitle()" @mouseout="btnTitle2()">
          主体备案号：粤ICP备2021103744号 </div>
        <div class="btnTitle2" id="move3" @click="tobtnTitle()" @mousemove="btnTitle3()" @mouseout="btnTitle4()">
          网站备案号：粤ICP备2021103744号-1</div>
        <div class="btnTitle2" id="move4" @click="tobtnTitle()" @mousemove="btnTitle5()" @mouseout="btnTitle6()">
          清声妙语APP备案号：粤ICP备2021103744号-2A</div>
      </div>

    </div>
  </div>
