import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/", //首页
    name: "Home",
    component: () => import("../views/Home.vue"),
  },
  {
    path: "/productAndTechnology", //产品与技术
    name: "ProductAndTechnology",
    component: () => import("../views/ProductAndTechnology.vue"),
  },
  {
    path: "/curriculumSystem", //课程体系
    name: "CurriculumSystem",
    component: () => import("../views/CurriculumSystem.vue"),
  },
  {
    path: "/about", //关于我们
    name: "About",
    component: () => import("../views/About.vue"),
  },
  {
    path: "/certificate", //关于我们
    name: "Certificate",
    component: () => import("../views/Certificate.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
