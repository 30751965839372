
export default {
  name: 'Index',
  data() {
    return {
      nav: [
        {
          type: '首页',
          router: '/'
        },
        {
          type: '产品与技术',
          router: '/productAndTechnology'
        },
        {
          type: '课程体系',
          router: '/curriculumSystem'
        },
        {
          type: '关于我们',
          router: '/about'
        },
        {
          type: '证书查询',
          router: '/certificate'
        },
      ],
      num: 0,
      topimgShow: false
    }
  },
  created() {
    var type = window.location.href.split('/')[window.location.href.split('/').length - 1]
    switch (type) {
      case '':
        this.num = 0
        break;
      case 'productAndTechnology':
        this.num = 1
        break;
      case 'curriculumSystem':
        this.num = 2
        break;
      case 'about':
        this.num = 3
        break;
      case 'certificate':
        this.num = 4
        break;
      default:
        break;
    }

    window.addEventListener('scroll', this.handleScroll, true)
  },
  methods: {
    setStyle() {
      document.body.clientWidth
      let obj
      if (document.body.clientWidth > 750) {
        obj = {
          minWidth: '1200px'
        }
      } else {
        obj = ''
      }

      return obj
    },
    jumpClick() {
      document.querySelector('#nav').scrollIntoView(true)

    },
    handleScroll() {
      var top = document.documentElement.scrollTop || document.body.scrollTop;
      if (top > 800) {
        this.topimgShow = true
      } else {
        this.topimgShow = false
      }


    },

    changeType(index) {
      this.num = index
    },
    desc() {
      document.getElementById('move1').style.color = "#EC6522"
    },
    desc2() {
      document.getElementById('move1').style.color = "#fff"
    },
    btnTitle() {
      document.getElementById('move2').style.color = "#EC6522"
    },
    btnTitle2() {
      document.getElementById('move2').style.color = "#fff"
    },
    btnTitle3() {
      document.getElementById('move3').style.color = "#EC6522"
    },
    btnTitle4() {
      document.getElementById('move3').style.color = "#fff"
    },
    btnTitle5() {
      document.getElementById('move4').style.color = "#EC6522"
    },
    btnTitle6() {
      document.getElementById('move4').style.color = "#fff"
    },
    todesc() {
      window.open('https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030402004913', '_blank');
    },
    tobtnTitle() {
      window.open('https://beian.miit.gov.cn', '_blank');
    },
  }
}
